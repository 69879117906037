import React, { useContext } from "react";
import { Link, useNavigation } from "react-router-dom";
import { SlotData } from "../../Utils/constants";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/AuthContext";
import { isEmpty } from "lodash";

const SlotSlider = () => {
  
  const { t } = useTranslation();
  const { lang, user, launchEGTCasino, launchCasino,withoutLogin } = useContext(AuthContext);
  const navigate = useNavigation();
  return (
    <>
      <div className="common-slider">
        <div className="heading-block">
          <h5>{t("Favourites")}</h5>
          {/* <Link to="/">{t("See_All")}</Link> */}
        </div>

        <div className="common-slider-box slot-slider-b">
          
          
            {SlotData.map((item) => {
              return (
                <div
                  onClick={() => {
                      if (!isEmpty(user)) {
                        if (item?.gameTypeCheck == "platForm") {
                          launchCasino({
                            platForm: item?.platForm,
                            gameType: item?.gameType,
                            casinoType: item?.casinoType,
                          });
                        } else if (item?.gameType == "egtGame") {
                          launchEGTCasino({
                            platForm: item?.platForm,
                            gameType: item?.gameTypeNew,
                            casinoType: item?.casinoType,
                          });
                        } else if (!isEmpty(item?.gameid)) {
                          launchEGTCasino({
                            platForm: item?.platForm,
                            gameType: item?.gameType,
                            casinoType: item?.casinoType,
                            gameid: item?.gameid,
                          });
                        } else {
                          if (
                            item?.platForm !== "" &&
                            item?.gameType !== "" &&
                            item?.casinoType !== ""
                          ) {
                            launchCasino({
                              platForm: item?.platForm,
                              gameType: item?.gameType,
                              casinoType: item?.casinoType,
                            });
                          }
                        }
                      } else {
                        withoutLogin()
                      }
                  }}
                  className="slider-items"
                >
                  <figure className="mb-0">
                    <img src={item?.icons} alt="" />
                  </figure>
                  <figcaption>
                    <h6>{lang == "bn" ? item?.titlebn : item?.title}</h6>
                    {/* <span>Daraz</span> */}
                  </figcaption>
                </div>
              );
            })}
          
        </div>
      </div>
    </>
  );
};

export default SlotSlider;
